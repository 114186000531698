<template>
  <div class="container mx-auto mdmax:px-4">
    <div class="max-w-sm mx-auto py-16 mdmax:pt-10 mdmax:pb-4 mdmax:max-w-none">
      <div class="text-3xl under4inch:text-2xl mdmax:text-2xl text-center font-bold mb-4">Employee Attendance</div>
      <div class="text-sm under4inch:text-xs text-center mb-8 under4inch:mb-4">
        Attendance for <span class="font-medium mdmax:block">{{ getYear() }}</span>
      </div>
      <div class="shadow-soft px-8 py-12 mdmax:py-8 rounded-lg">
        <div class="form">
          <div class="mb-4">
            <TextField type="text" id="email" name="email" label="Email" placeholder="Your email" v-model="formPayload.emailKaryawan" :borderEnabled="true" :error="errors['emailKaryawan']" />
          </div>
          <div class="text-xs pl-3 text-left block mb-1">Attendance Type</div>
          <div class="flex gap-4 ml-2">
            <Checkbox
              v-for="(item, index) in attendanceType"
              :key="index + Math.random()"
              :id="item.id"
              :text="item.label"
              :checked="item.checked"
              :changeCheck="changeCheck"
              :disabled="item.disabled"
              class="mb-3 mdmax:mr-3"
            />
          </div>
          <transition name="fade" mode="out-in">
            <div class="text-system-error mb-14 text-xs" v-if="errorCheckbox">{{ errorCheckbox }}</div>
          </transition>
        </div>
        <div class="text-center mt-8">
          <Button @action="doSubmit" buttonText="Submit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { isNotEmptyError, showVueToast } from '@/utils'
import dayjs from 'dayjs'
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const CONFIG = {
  emailKaryawan: 'Corporate Email'
}

export default {
  components: {
    TextField: () => import(/* webpackChunkName: "DropDown" */ '@/components/forms/TextField'),
    Checkbox: () => import(/* webpackChunkName: "checkbox" */ '@/components/forms/Checkbox'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      formPayload: {
        emailKaryawan: ''
      },
      attendanceType: [
        {
          id: 'CHECK_IN',
          label: 'Start',
          checked: false,
          disabled: false
        },
        {
          id: 'CHECK_OUT',
          label: 'Leave',
          checked: false,
          disabled: false
        }
      ],
      errors: [],
      errorCheckbox: '',
      notDubbleClick: false
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    email() {
      return this.getOnlyText(this.formPayload.emailKaryawan)
    },
    attendanceTypeChecked() {
      return this.attendanceType.filter((item) => item.checked)
    }
  },
  watch: {
    email(value) {
      // eslint-disable-next-line no-useless-escape
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      value === '' ? (this.errors['emailKaryawan'] = "Email can't be empty!") : (this.errors['emailKaryawan'] = '')
      !emailPattern.test(this.formPayload.emailKaryawan) ? (this.errors['emailKaryawan'] = 'Incorrect email format!') : (this.errors['emailKaryawan'] = '')
    },
    attendanceTypeChecked() {
      this.attendanceTypeChecked.length < 1 ? (this.errorCheckbox = 'Please select at least one!') : (this.errorCheckbox = '')
    }
  },
  mounted() {
    let query = null
    if (Object.keys(this.$route.query).length > 0) {
      query = this.$route.query
      if (query.email) {
        this.formPayload.emailKaryawan = query.email
      }
      if (query.type) {
        this.attendanceType.forEach((item) => {
          if (item.id === query.type) {
            item.checked = true
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('attendance', ['postAttendances']),
    getOnlyText(str) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = str
      return tmp.textContent.split(' ').join('') || tmp.innerText.split(' ').join('') || ''
    },
    allValidation() {
      let pass = true

      const error = isNotEmptyError(this.formPayload)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})

      // eslint-disable-next-line no-useless-escape
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.formPayload.emailKaryawan === '') {
        this.errors['emailKaryawan'] = "Email can't be empty!"
        pass = false
      } else if (!emailPattern.test(this.formPayload.emailKaryawan)) {
        this.errors['emailKaryawan'] = 'Incorrect email format!'
        pass = false
      }

      if (this.attendanceTypeChecked.length === 0) {
        this.errorCheckbox = 'Please select at least one!'
        pass = false
      }
      return pass
    },
    doSubmit() {
      const notValited = true
      if (!this.allValidation()) return
      const allianceNeeds = this.attendanceTypeChecked.map((item) => {
        return item.id
      })
      const payloads = {
        email: this.formPayload.emailKaryawan,
        customerId: 1,
        actionType: allianceNeeds[0]
      }
      this.showLoading()
      this.postAttendances({
        payloads
      })
        .then((res) => {
          if (res.code === 200) {
            if (notValited) {
              if (this.notDubbleClick) return
              showVueToast("You've succesfully submit the attendance", 'success', 3000)
              this.notDubbleClick = true
              setTimeout(() => {
                location.reload()
                this.notDubbleClick = false
              }, 3000)
            }
          }
        })
        .catch(() => {
          if (notValited) {
            if (this.notDubbleClick) return
            showVueToast('Employee not found', 'error', 3000)
            this.notDubbleClick = true
            setTimeout(() => {
              location.reload()
              this.notDubbleClick = false
            }, 3000)
          }
        })
    },
    changeCheck(id) {
      switch (id) {
        case 'CHECK_IN':
          this.attendanceType[0].checked = !this.attendanceType[0].checked
          this.attendanceType[1].checked = false
          break
        case 'CHECK_OUT':
          this.attendanceType[1].checked = !this.attendanceType[1].checked
          this.attendanceType[0].checked = false
          break
      }
    },
    getYear() {
      const date = new Date()
      const formatDate = dayjs(date).format('dddd, DD MMMM YYYY - HH:mm')
      return formatDate
    }
  }
}
</script>
